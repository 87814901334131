import axios from "axios";
import jsonwebtoken from "jsonwebtoken";
import Swal from "sweetalert2";
//import { shallowEqual, useDispatch, useSelector } from "react-redux";

import {
  API_CREATE_PAYPAL_PLAN,
  API_AFTER_PAYPAL_SUBSCRIPTION,
  API_GET_COUPON_PERCENTAGE,
  API_RESUME_PAUSE_SUBSCRIPTION,
  API_CREATE_PAYSTACK_PLAN,
  API_AFTER_PAYSTACK_SUBSCRIPTION,
  API_GET_SYSTEM_COUNTS,
  API_GET_DASHBOARD_STATS,
  API_SAVE_REFERRAL_CODE,
  API_IS_INVITE_LINK_USABLE,
  API_GET_BUSINESS,
  API_ADD_BUSINESS,
  API_UPDATE_BUSINESS,
  API_GET_USER_PROFILE,
  API_UPDATE_USER_PROFILE,
  API_VIEW_USER_PROFILE,
  API_GET_JWT,
  API_GET_BUSINESS_MODULES,
  API_ADD_UPDATE_INVITE_LINK,
  API_GET_INVITE_LINK,
  API_DELETE_INVITE_LINK,
  API_GET_ALL_USERS,
  API_INVITE_MULTIPLE_USERS,
  API_CHANGE_USER_STATUS,
  API_DELETE_USER,
  API_INVITE_USER,
  API_SWITCH_USER_PROFILE,
  API_USERS_LIST,
  API_GET_BUSINESS_DETAILS,
  API_URL,
  REDIRECT_URI,
  CLIENT_ID,
  CLIENT_SECRET,
  JWT_SECRET,
  OAUTH_URL,
  API_UPDATE_PROFILE_PHOTO,
  API_DELETE_PROFILE,
  API_UPDATE_PROFILE,
  API_GET_PROFILE_DETAILS,
  API_ADD_PROFILE,
  API_GET_PROFILES,
  API_GET_DEFAULT_PROFILES,
  API_SWITCH_BUSINESS,
  API_DELETE_BUSINESS,
  API_GET_MODULE_PERMISSION,
  API_GET_MODULE_ACCESS,
  API_ADD_MODULE,
  API_UPDATE_MODULE,
  API_DELETE_MODULE,
  API_GET_MODULES_HAVING_COST,
  API_GET_APP_KEYS,
  API_GET_DEPARTMENTS,
  API_ADD_DEPARTMENT,
  API_UPDATE_DEPARTMENT,
  API_DELETE_DEPARTMENT,
  API_DEFAULT_MODULE,
  API_GET_BUSINESS_ROLES,
  API_GET_DEFAULT_ROLES,
  API_ADD_UPDATE_ROLE,
  API_DELETE_ROLE,
  API_CREATE_SUBSCRIPTION,
  API_CANCEL_SUBSCRIPTION,
  API_CREATE_STRIPE_CUSTOMER,
  API_CREATE_STRIPE_CHECKOUT_SESSION,
  API_GET_ALL_BUSINESSES_LIST,
  API_SEND_BUSINESS_JOIN_REQUEST,
  API_GET_BUSINESS_REQUEST_LIST,
  API_GET_BUSINESS_MODULE_ID,
  API_REJECT_USER_REQUEST,
  API_USER_UPLOAD_HISTORY,
  API_SEND_APP_KEYS,
  API_GET_USER_MODULE_SETTING
} from "./../../../../config";
export const APIURL = process.env.REACT_APP_API_ENDPOINT;

export async function get_user_module_setting(data) {
  data["api"] = API_GET_USER_MODULE_SETTING;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response;
}

export async function create_paystack_plan(data) {
  data["api"] = API_CREATE_PAYSTACK_PLAN;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response;
}

export async function create_paypal_plan(data) {
  data["api"] = API_CREATE_PAYPAL_PLAN;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response;
}

export async function get_coupon_percentage(data) {
  data["api"] = API_GET_COUPON_PERCENTAGE;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response;
}

export async function after_paystack_subscription(data) {
  data["api"] = API_AFTER_PAYSTACK_SUBSCRIPTION;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response;
}

export async function after_paypal_subscription(data) {
  data["api"] = API_AFTER_PAYPAL_SUBSCRIPTION;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response;
}

export async function save_referral_code(data) {
  data["api"] = API_SAVE_REFERRAL_CODE;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response;
}

export async function update_profile_photo(data) {
  data["api"] = API_UPDATE_PROFILE_PHOTO;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response;
}

export async function invite_multiple_users(data) {
  data["api"] = API_INVITE_MULTIPLE_USERS;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response;
}

export async function is_invite_link_usable(data) {
  data["api"] = API_IS_INVITE_LINK_USABLE;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response;
}

export async function getToken(data) {
  let authorization_data = CLIENT_ID + ":" + CLIENT_SECRET;
  let authorization = Buffer.from(authorization_data).toString("base64");

  let map = {
    grant_type: "authorization_code",
    code: data["code"],
    redirect_uri: data["REDIRECT_URI"] ? data["REDIRECT_URI"] : REDIRECT_URI,
    client_id: CLIENT_ID
  };

  var formBody = [];
  for (var property in map) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(map[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  let response = await fetch(OAUTH_URL, {
    method: "POST",
    async: false,
    body: formBody,
    headers: {
      authorization: "Basic " + authorization,
      "content-type": "application/x-www-form-urlencoded"
    }
  });
  const ret_data = await response.json();
  return ret_data;
}

export async function create_and_save_jwt_token(data) {
  let map = {
    access_token: data["access_token"],
    api: API_GET_JWT,
    jwt_secret: JWT_SECRET
  };
  if (data.hasOwnProperty("invitation_code")) {
    if (data["invitation_code"] === "0" || data["invitation_code"] === "1") {
      map["is_user"] = data["invitation_code"];
    } else {
      //this token is in base64
      let invitation_code = atob(data["invitation_code"]);
      let code_json = JSON.parse(invitation_code);

      //here we can reach with two cases
      //1. user has been invited from invite user page and they got email of invite
      //2. user has got an invite link and they clicked on it

      if (code_json.hasOwnProperty("invite_link") && code_json.invite_link) {
        map["default_business_id"] = code_json.business_id;
        map["invite_link"] = data["invitation_code"];
      } else {
        let identity_id = parseInt(code_json.id);

        if (identity_id === 2) {
          //the user existed and it has come from login screen
          map["default_business_id"] = code_json.code;
        } else if (identity_id === 1) {
          //the user did not exist and has come from register screen
          map["uuid"] = code_json.code;
        }
      }
    }
  }
  map["require_setting"] = "1";
  let jsonmap = JSON.stringify(map);

  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response.data;
}

export async function get_user_info_from_jwt_token(token) {
  try {
    let data = jsonwebtoken.verify(token, JWT_SECRET);
    let response = {};
    response["access_token"] = data.access_token;
    response["status"] = true;
    if (data.hasOwnProperty("user_info")) {
      response["user_name"] = data.user_info.Username;
      let attributes = data.user_info.UserAttributes;
      for (let i = 0; i < attributes.length; i++) {
        if (attributes[i].Name === "name") {
          response["user_complete_name"] = attributes[i].Value;
        } else if (attributes[i].Name === "email") {
          response["user_email"] = attributes[i].Value;
        } else if (attributes[i].Name === "sub") {
          response["user_id"] = attributes[i].Value;
        }
      }
    }
    response["is_admin"] = data.is_admin;
    response["business_id"] = data.business_id;
    response["profile"] = parseInt(data.profile);
    response["department_id"] = parseInt(data.department_id);

    response["role_id"] = data.role_id;
    response["role_level"] = data.role_level;

    response["user_status"] = data.user_status;

    response["expiry_time"] = data.exp;
    response["is_user"] = data.is_user;
    return response;
  } catch (error) {
    if (error.hasOwnProperty("name") && error.name == "TokenExpiredError") {
      show_expired_swal();
    }
  }
}

export async function get_business_details(data) {
  data["api"] = API_GET_BUSINESS_DETAILS;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);

  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response.data;
}

export async function get_departments(data) {
  data["api"] = API_GET_DEPARTMENTS;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response.data;
}

export async function add_update_department(data) {
  if (data["department_id"] === "") {
    data["api"] = API_ADD_DEPARTMENT;
  } else {
    data["api"] = API_UPDATE_DEPARTMENT;
  }
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response.data;
}

export async function delete_department(data) {
  data["api"] = API_DELETE_DEPARTMENT;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response.data;
}
export async function get_business_list(data) {
  data["api"] = API_GET_BUSINESS;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response.data;
}

export async function update_business(data) {
  data["api"] = API_UPDATE_BUSINESS;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = await fetch(API_URL, {
    method: "POST",
    async: false,
    body: jsonmap,
    headers: { "content-type": "application/json" }
  });
  const ret_data = await response.json();
  return ret_data;
}

export async function add_business(data) {
  data["api"] = API_ADD_BUSINESS;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);

  let response = await fetch(API_URL, {
    method: "POST",
    async: false,
    body: jsonmap,
    headers: { "content-type": "application/json" }
  });
  const ret_data = await response.json();
  return ret_data;
}

export async function delete_business(data) {
  data["api"] = API_DELETE_BUSINESS;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = await fetch(API_URL, {
    method: "POST",
    async: false,
    body: jsonmap,
    headers: { "content-type": "application/json" }
  });
  const ret_data = await response.json();
  return ret_data;
}

export async function switch_business(data) {
  data["api"] = API_SWITCH_BUSINESS;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = await fetch(API_URL, {
    method: "POST",
    async: false,
    body: jsonmap,
    headers: { "content-type": "application/json" }
  });
  const ret_data = await response.json();
  return ret_data;
}

export async function get_app_keys(data) {
  data["api"] = API_GET_APP_KEYS;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = await fetch(API_URL, {
    method: "POST",
    async: false,
    body: jsonmap,
    headers: { "content-type": "application/json" }
  });
  const ret_data = await response.json();
  return ret_data;
}

export async function get_business_modules(data) {
  data["api"] = API_GET_BUSINESS_MODULES;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);

  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response.data;
}

export async function default_modules(data) {
  data["api"] = API_DEFAULT_MODULE;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response.data;
}

export async function add_update_module(data) {
  //while creating or updating a module, we also need email of the logged in user
  let user_info = await get_user_info_from_jwt_token(data["token"]);

  data["email"] = user_info.user_email;

  if (data["mode"] === "create" || data["mode"] === "add") {
    data["api"] = API_ADD_MODULE;
  } else {
    data["api"] = API_UPDATE_MODULE;
  }

  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response.data;
}

export async function delete_module(data) {
  data["api"] = API_DELETE_MODULE;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response.data;
}

export async function add_update_profile(data) {
  let api_name = "";
  if (data.hasOwnProperty("profile_id")) {
    //edit profile
    api_name = API_UPDATE_PROFILE;
  } else {
    //add profile
    api_name = API_ADD_PROFILE;
  }

  data["api"] = api_name;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response.data;
}

export async function get_business_profiles(data) {
  data["api"] = API_GET_PROFILES;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);

  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response.data;
}

export function show_expired_swal() {
  Swal.fire({
    icon: "error",
    title: "Error",
    text: "Your session has expired, please login again",
    showConfirmButton: true,
    confirmButtonText: "OK",
    dangerMode: true,
    customClass: {
      confirmButton: "swal2-confirm btn font-weight-bold btn-light"
    }
  }).then(async result => {
    window.location.href = "/logout";
  });
}

export async function delete_profile(data) {
  data["api"] = API_DELETE_PROFILE;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);

  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response.data;
}

export async function get_profile_details(data) {
  data["api"] = API_GET_PROFILE_DETAILS;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response.data;
}

export async function get_default_roles(data) {
  data["api"] = API_GET_DEFAULT_ROLES;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response.data;
}

export async function get_default_profiles(data) {
  data["api"] = API_GET_DEFAULT_PROFILES;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response.data;
}

export async function invite_user(data) {
  data["api"] = API_INVITE_USER;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response.data;
}

export async function get_all_users(data) {
  data["api"] = API_GET_ALL_USERS;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response.data;
}

export async function users_list(data) {
  data["api"] = API_USERS_LIST;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response.data;
}

export async function change_user_status(data) {
  data["api"] = API_CHANGE_USER_STATUS;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response.data;
}

export async function delete_user(data) {
  data["api"] = API_DELETE_USER;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response.data;
}

export async function switch_user_profile(data) {
  data["api"] = API_SWITCH_USER_PROFILE;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response.data;
}

export async function get_business_roles(data) {
  data["api"] = API_GET_BUSINESS_ROLES;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response.data;
}

export async function add_update_role(data) {
  data["api"] = API_ADD_UPDATE_ROLE;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response.data;
}

export async function delete_role(data) {
  data["api"] = API_DELETE_ROLE;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response.data;
}

export async function get_module_access(data) {
  data["api"] = API_GET_MODULE_ACCESS;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response.data;
}

export async function create_stripe_checkout_session(data) {
  data["api"] = API_CREATE_STRIPE_CHECKOUT_SESSION;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response.data;
}

export async function create_subscription(data) {
  data["api"] = API_CREATE_SUBSCRIPTION;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response.data;
}
export async function delete_subscription(data) {
  data["api"] = API_CANCEL_SUBSCRIPTION;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response.data;
}

export async function resume_pause_subscription(data) {
  data["api"] = API_RESUME_PAUSE_SUBSCRIPTION;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response.data;
}

export async function get_modules_having_cost(data) {
  data["api"] = API_GET_MODULES_HAVING_COST;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);

  let response = await axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response.data;
}

export function create_stripe_customer(data) {
  data["api"] = API_CREATE_STRIPE_CUSTOMER;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);

  axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
}
export function get_module_permission(data) {
  data["api"] = API_GET_MODULE_PERMISSION;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response;
}

export function handle_discourse_auth(data, API_URL) {
  let jsonmap = JSON.stringify(data);
  let response = axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response;
}

export function get_all_businesses_list(data) {
  data["api"] = API_GET_ALL_BUSINESSES_LIST;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response;
}

export function request_to_join_business(data) {
  data["api"] = API_SEND_BUSINESS_JOIN_REQUEST;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response;
}

export function get_business_requests_list(data) {
  data["api"] = API_GET_BUSINESS_REQUEST_LIST;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response;
}

export function get_module_business_id(data) {
  data["api"] = API_GET_BUSINESS_MODULE_ID;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response;
}

export function reject_user_request(data) {
  data["api"] = API_REJECT_USER_REQUEST;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response;
}

export function get_user_upload_history(data) {
  data["api"] = API_USER_UPLOAD_HISTORY;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response;
}

export function get_user_profile(data) {
  data["api"] = API_GET_USER_PROFILE;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response;
}

export function update_user_profile(data) {
  data["api"] = API_UPDATE_USER_PROFILE;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response;
}

export function view_user_profile(data = {}) {
  data["api"] = API_VIEW_USER_PROFILE;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response;
}

export function add_update_invite_link(data) {
  data["api"] = API_ADD_UPDATE_INVITE_LINK;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response;
}

export function get_invite_link(data) {
  data["api"] = API_GET_INVITE_LINK;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response;
}

export function delete_invite_link(data) {
  data["api"] = API_DELETE_INVITE_LINK;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response;
}

export function get_system_counts(data) {
  data["api"] = API_GET_SYSTEM_COUNTS;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response;
}

export function get_dashboard_stats(data) {
  data["api"] = API_GET_DASHBOARD_STATS;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response;
}

export async function send_app_keys(data) {
  data["api"] = API_SEND_APP_KEYS;
  data["secret"] = JWT_SECRET;
  let jsonmap = JSON.stringify(data);
  let response = axios.post(API_URL, jsonmap, {
    headers: {
      "content-type": "application/json"
    }
  });
  return response;
}
